<template>
  <v-container fluid ma-0 pa-0 fill-heigh>
    <v-card color="grey lighten-4" flat height="50px" tile>
      <v-app-bar color="primary darken-3" dense dark fixed>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>Cambio24 - operaciones</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="cerrarSesion">
              <v-list-item-title>Cerrar Sesión</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </v-card>

    <v-navigation-drawer
      disable-route-watcher
      v-model="drawer"
      :color="color"
      :right="right"
      :src="bg"
      fixed
      temporary
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line :class="miniVariant && 'px-0'" class="pl-0">
          <v-list-item-avatar>
            <v-icon>mdi-face</v-icon>
          </v-list-item-avatar>

          <v-list-item-content v-if="estado.logueado">
            <v-list-item-title>{{ cemail }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ cnombres }} {{ capellidos }}
            </v-list-item-subtitle>
            <v-list-item-subtitle class="green--text font-weight-medium">{{
              ccargo
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.ruta"
          @click="irRuta()"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Barmenu",
  data() {
    return {
      drawer: false,
      items: [
        { title: "Inicio", icon: "mdi-home", ruta: "/" },
        { title: "Tipo de cambio (Efectivo)", icon: "mdi-check", ruta: "/tipo-cambio-efectivo" },
        { title: "Empresas", icon: "mdi-account-group", ruta: "/empresas" },
        { title: "Clientes", icon: "mdi-account-group", ruta: "/clientes" },
        {
          title: "Operaciones",
          icon: "mdi-cash-multiple",
          ruta: "/operaciones",
        },
        {
          title: "Operaciones en Efectivo",
          icon: "mdi-cash-multiple",
          ruta: "/operaciones-en-efectivo",
        },
        {
          title: "Cuentas",
          icon: "mdi-archive-alert",
          ruta: "/cuentas-usuario",
        },
      ],
      color: "white",
      colors: ["orange"],
      right: false,
      permanent: false,
      miniVariant: false,
      background: false,
      cnombres: "",
      capellidos: "",
      ccargo: "",
      cemail: "",
      idusuario: "",
    };
  },
  computed: {
    bg() {
      return this.background
        ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        : undefined;
    },
    ...mapState("usuario", ["usuarios", "estado"]),
  },
  created() {
    //console.log(this.user)
    if (this.estado.logueado) {
      this.cemail = this.usuarios.correo;
      this.cnombres = this.usuarios.nombres;
      this.capellidos = this.usuarios.apellidos;
      this.ccargo = this.usuarios.cargo;
    }
  },
  methods: {
    ...mapActions("usuario", ["logout"]),
    estadoBar() {
      this.miniVariant = !this.miniVariant;
      this.showapp = !this.showapp;
    },
    async cerrarSesion() {
      await this.logout();
      this.$router.push("/login");
    },
    irRuta() {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "auto",
        });
      }, 100);
      this.drawer = false;
    },
  },
};
</script>