import { direccion } from '../_extra/configuracion'

const state = {
    oresultado: {},
    operaciones: [],
    operacionesefectivo: [],
}

const actions = {
    async listarOperaciones({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/operaciones.php`, opciones)
            const contenido = await response.json()
            commit('obtnerOperaciones', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarDiezUltimasOperaciones({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/operaciones.php?accion=li002`, opciones)
            const contenido = await response.json()
            commit('obtenerDiezOperaciones', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarTodoOperaciones({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/operaciones.php?accion=li003`, opciones)
            const contenido = await response.json()
            commit('obtenerTodoOperaciones', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarTodoOperacionesEfectivo({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/operaciones.php?accion=li006`, opciones)
            const contenido = await response.json()
            commit('obtenerTodoOperacionesEfectivo', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async registrar({commit}, elementoRegistrar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoRegistrar)
        }
        try {
            //console.log(JSON.stringify(elementoRegistrar))
            let respuesta = await fetch(`${direccion().url}/operaciones.php?enviar=2021`, opciones)
            const contenido = await respuesta.json()
            commit('registrarOperacion', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editarEstado({commit}, elemento) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            console.log(JSON.stringify(elemento))
            let respuesta = await fetch(`${direccion().url}/operaciones.php?accion=ed002&id=${elemento.id}`, opciones)
            const contenido = await respuesta.json()
            commit('editarEstadoOperacion', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editarEstadoEfectivo({commit}, elemento) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            console.log(JSON.stringify(elemento))
            let respuesta = await fetch(`${direccion().url}/operaciones.php?accion=ed006&id=${elemento.id}`, opciones)
            const contenido = await respuesta.json()
            commit('editarEstadoOperacionEfectivo', contenido)
        } catch (error) {
            console.error(error)
        }
    }
}

const mutations = {
    obtnerOperaciones(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.operaciones = contenido
    },
    obtenerDiezOperaciones(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.operaciones = contenido
    },
    obtenerTodoOperaciones(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.operaciones = contenido
    },
    obtenerTodoOperacionesEfectivo(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.operacionesefectivo = contenido
    },
    registrarOperacion(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
    editarEstadoOperacion(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
    editarEstadoOperacionEfectivo(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
}

export const operacion = {
    namespaced: true,
    state,
    actions,
    mutations
}