var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.crearPDF}},[_vm._v(" Primary ")])],1),_c('v-data-table',{ref:"myTable",staticClass:"elevation-1",attrs:{"headers":_vm.computedHeaders,"items":_vm.empresas,"sort-by":"id","items-per-page":5,"footer-props":{
      'items-per-page-options': [5, 10, -1],
      'items-per-page-text': 'Por pagina',
      'items-per-page-all-text': 'Todo',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogEditar),callback:function ($$v) {_vm.dialogEditar=$$v},expression:"dialogEditar"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Actualizar datos del cliente")])]),_c('v-card-text',{staticClass:"pb-0"},[_c('v-form',{ref:"formCliente",model:{value:(_vm.formulario.validar),callback:function ($$v) {_vm.$set(_vm.formulario, "validar", $$v)},expression:"formulario.validar"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.tipos,"label":"Tipo de documento","rules":[
                            function (v) { return !!v || 'Seleccione el tipo de documento'; } ],"outlined":""},model:{value:(_vm.elem.tipodocumento),callback:function ($$v) {_vm.$set(_vm.elem, "tipodocumento", $$v)},expression:"elem.tipodocumento"}})],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{ref:"focusdocumento",attrs:{"label":"Número de documento","rules":[function (v) { return !!v || 'El número es requerido'; }],"outlined":"","required":""},model:{value:(_vm.elem.nrodocumento),callback:function ($$v) {_vm.$set(_vm.elem, "nrodocumento", $$v)},expression:"elem.nrodocumento"}})],1)],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombres","rules":[function (v) { return !!v || 'El nombre es requerido'; }],"outlined":"","required":""},model:{value:(_vm.elem.nombres),callback:function ($$v) {_vm.$set(_vm.elem, "nombres", $$v)},expression:"elem.nombres"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Apellido Paterno","rules":[
                        function (v) { return !!v || 'El apellidos parterno es requerido'; } ],"outlined":"","required":""},model:{value:(_vm.elem.apaterno),callback:function ($$v) {_vm.$set(_vm.elem, "apaterno", $$v)},expression:"elem.apaterno"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Apellido Materno","rules":[
                        function (v) { return !!v || 'El apellido materno es requerido'; } ],"outlined":"","required":""},model:{value:(_vm.elem.amaterno),callback:function ($$v) {_vm.$set(_vm.elem, "amaterno", $$v)},expression:"elem.amaterno"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Dirección","rules":[function (v) { return !!v || 'su direccion es requerida'; }],"outlined":"","required":""},model:{value:(_vm.elem.direccion),callback:function ($$v) {_vm.$set(_vm.elem, "direccion", $$v)},expression:"elem.direccion"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.emailReglas,"label":"Email","outlined":"","required":""},model:{value:(_vm.elem.email),callback:function ($$v) {_vm.$set(_vm.elem, "email", $$v)},expression:"elem.email"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Celular","rules":[
                        function (v) { return !!v || 'Su numero de contacto es requerido'; } ],"outlined":"","required":""},model:{value:(_vm.elem.celular),callback:function ($$v) {_vm.$set(_vm.elem, "celular", $$v)},expression:"elem.celular"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nacionalidad","rules":[
                        function (v) { return !!v || 'El pais de nacionalidad es requerido'; } ],"outlined":"","required":""},model:{value:(_vm.elem.nacionalidad),callback:function ($$v) {_vm.$set(_vm.elem, "nacionalidad", $$v)},expression:"elem.nacionalidad"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.peps,"label":"PEP","rules":[function (v) { return !!v || 'Seleccione si es o no es PEP'; }],"outlined":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}])},[_c('div',{attrs:{"max-width":"500"}},[_vm._v(" Personas Expuestas Políticamente son las personas naturales, "),_c('br'),_vm._v(" nacionales o extranjeras, que cumplen o que en los últimos cinco (5) años han cumplido "),_c('br'),_vm._v(" funciones públicas destacadas o funciones prominentes en una organización internacional. ")]),_c('span')])]},proxy:true}]),model:{value:(_vm.elem.pep),callback:function ($$v) {_vm.$set(_vm.elem, "pep", $$v)},expression:"elem.pep"}})],1),_c('v-col',{staticClass:"pb-0 pt-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.ocupaciones,"label":"Ocupación","rules":[function (v) { return !!v || 'La ocupación es requerida'; }],"outlined":""},model:{value:(_vm.elem.ocupacion),callback:function ($$v) {_vm.$set(_vm.elem, "ocupacion", $$v)},expression:"elem.ocupacion"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cerrar}},[_vm._v(" Salir ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.validar,"loading":_vm.loading},on:{"click":_vm.modificar}},[_vm._v(" Modificar ")])],1)],1)],1)]},proxy:true},{key:"item.contrasena",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"80px"}},[_vm._v(" "+_vm._s(item.contrasena)+" ")])]}},{key:"item.acciones",fn:function(ref){
                      var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}},{key:"footer.page-text",fn:function(ref){
                      var pageStart = ref.pageStart;
                      var pageStop = ref.pageStop;
                      var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No hay registros ")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }