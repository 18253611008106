import Vue from 'vue'
import Vuex from 'vuex'
import { direccion } from '../_extra/configuracion'

import { alerta } from './Alerta.modulo'
import { usuario } from './Usuario.modulo'
import { empresa } from './Empresa.modulo'
import { cuentabancaria } from './Cuentabancaria.modulo'
import { tipocambio } from './Tipocambio.modulo'
import { cuentausuario } from './Cuentausuario.modulo'
import { operacion } from './Operacion.modulo'
import { cliente } from './Cliente.modulo'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pivot: ''
  },
  mutations: {
    setGlobalError (state, error) {
      state.pivot = error
    },
    checkLoginToken(state, resultado){
      state.pivot = resultado
    }
  },
  actions: {
    async checkLogin({commit},token) {
      const pedidoOpciones = {
          method: 'POST',
          headers: { 'Content-type':'application/json' },
          body: JSON.stringify({ token })
      }
      try {
          let respuesta = await fetch(`${direccion().url}/usuarios.php?accion=ag004`, pedidoOpciones)
          const contenido = await respuesta.json()
          let codigo = '0'
          if(contenido.coderror === 'Expired token')
          {
            localStorage.removeItem('usuario')
            codigo = '1'
          } 
          else if(contenido.error === 'Syntax error, malformed JSON')
          {
            localStorage.removeItem('usuario')
            codigo = '2'
          }
          commit('checkLoginToken', codigo)
      } catch (error) {
          console.error(error)
      }
    }
  },
  modules: {
    alerta,
    usuario,
    empresa,
    cuentabancaria,
    tipocambio,
    cuentausuario,
    operacion,
    cliente
  }
})
