<template>
  <div class="home pl-5 pr-5">
    <v-row >
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <div class="pa-4 pl-0 "> <span class="rounded amber lighten-2 pa-2 mb-8 text-body-1 font-weight-medium">Todas las operaciones a la fecha</span> </div>
          <listarTodoOperaciones />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import listarTodoOperaciones from '../components/listadotodooperacionesefectivo.vue'
export default {
  name: 'operacionesenefectivo',
  components: {
    listarTodoOperaciones
  },
  data () {
    return {
      formulario : {
        validar: true,
        cargar: false
      },
    }
  },
  computed: {
     diplayClass() {
      let classString = ''
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          classString = 'subtitle-2 text-no-wrap justify-center'
          break
        case 'sm':
          classString = 'subtitle-1 justify-center'
          break
        case 'md':
          classString = 'h5 justify-center'
          break
        case 'lg':
          classString = 'h6 justify-center'
          break
        case 'xl':
          classString = 'h6 justify-center'
          break
      }
      return classString
    }
  },
  async created() {

  },
  methods: {
  },
}
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>
