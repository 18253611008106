
<template>
  <div>
    <v-data-table
      :headers="computedHeaders"
      :items="operacionesefectivo"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [5, 10, -1],
        'items-per-page-text': 'Por pagina',
        'items-per-page-all-text': 'Todo',
      }"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogEditar" max-width="300px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Modificar cambio</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="elem.estado"
                      :items="items"
                      label="Estado"
                    >
                      <template v-slot:item="slotProps">
                        <v-chip :color="getColor(slotProps.item)" dark>
                          {{ slotProps.item }}
                        </v-chip>
                      </template>
                      <template #selection="{ item }">
                        <v-chip :color="getColor(item)" dark>{{ item }}</v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cerrar"> Salir </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!validar"
                :loading="loading"
                @click="modificar"
              >
                Modificar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.cliente`]="{ item }">
        <div>
          <v-chip
            v-if="item.perfil == 'c'"
            :color="`blue lighten-4`"
            class="ml-0 mr-0 black--text"
            label
            small
          >
            <v-icon dark small> mdi-account </v-icon>
            Persona
          </v-chip>
          <v-chip
            v-else
            :color="`green lighten-4`"
            class="ml-0 mr-0 black--text"
            label
            small
          >
            <v-icon dark small> mdi-domain </v-icon>
            Empresa
          </v-chip>
          <div class="font-weight-medium mt-1">{{ item.cliente }}</div>
        </div>
      </template>
      <template v-slot:[`item.tipocambio`]="{ item }">
        <div class="text-end"> {{item.tipocambio}}
        </div>
      </template>
      <template v-slot:[`item.monrecepcion`]="{ item }">
        <div class="text-center">
           <img
            :src="
              require('@/assets/' +
                item.monrecepcion.toLowerCase() +
                '-flag.svg')
            "
            :class="['mr-2', 'em', 'cuentas-size']"
          />  {{item.monrecepcion}}
        </div>
      </template>
      <template v-slot:[`item.monenvio`]="{ item }">
        <div class="text-center">
           <img
            :src="
              require('@/assets/' +
                item.monenvio.toLowerCase() +
                '-flag.svg')
            "
            :class="['mr-2', 'em', 'cuentas-size']"
          />  {{item.monenvio}}
        </div>
      </template>
      <template v-slot:[`item.desde`]="{ item }">
        <div>
          <img
            :src="
              require('@/assets/' + item.monenvio.toLowerCase() + '-flag.svg')
            "
            :class="['mr-2', 'em', 'cuentas-size']"
          />
        </div>
      </template>
      <template v-slot:[`item.montohacia`]="{ item }">
        <div>
          <span class="font-weight-medium blue--text">{{
            item.montohacia
          }}</span>
        </div>
      </template>
      <template v-slot:[`item.hacia`]="{ item }">
        <div>
          <img
            :src="
              require('@/assets/' +
                item.monrecepcion.toLowerCase() +
                '-flag.svg')
            "
            :class="['mr-2', 'em', 'cuentas-size']"
          />
        </div>
      </template>
      <template v-slot:[`item.llego`]="{ item }">
        <div>
          {{ item.bancollego }} | {{ item.monedallego }}
          <img
            :src="
              require('@/assets/' +
                item.monedallego.toLowerCase() +
                '-flag.svg')
            "
            :class="['mr-2', 'em', 'cuentas-size']"
          />
          <br />
          <span class="font-weight-medium">{{ item.cuentallego }}</span>
        </div>
      </template>
      <template v-slot:[`item.estado`]="props">
        <v-chip :color="getColor(props.item.estado)" dark>
          {{ props.item.estado }}
        </v-chip>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              class="mr-4"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip bottom v-if="item.procedencia != ''">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              color="blue-grey"
              @click="verProcedencia(item)"
            >
              mdi-slope-uphill
            </v-icon>
          </template>
          <span>Procedencia</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              class=""
              @click="imprimirOperacion(item)"
              color="red lighten-1"
            >
              mdi-file-pdf-box
            </v-icon>
          </template>
          <span>Imprimir</span>
        </v-tooltip>
      </template>
      <template
        v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
      </template>
      <template v-slot:no-data> No hay registros </template>
    </v-data-table>
    <v-row>
      <v-dialog v-model="dialogVerProcedencia" max-width="300px">
        <v-card>
          <v-card-title>
            <span class="text-h6">Procedencia del dinero</span>
          </v-card-title>

          <v-card-text class="text-body-1 font-weight-medium teal--text">
            {{ procedencia }}
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrarVerProcedencia">
              Salir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  name: "listatodooperacionesefectivo",
  components: {},
  data() {
    return {
      procedencia: "",
      dialogVerProcedencia: false,
      sortBy: "id",
      sortDesc: true,
      loading: false,
      validar: true,
      dialogEditar: false,
      formulario: {
        validar: true,
        cargar: false,
      },
      elem: {
        estado: "",
        verificacion: "-",
        id: "",
        nombre: "",
        nro: "",
        email: "",
      },
      emailReglas: [
        (v) => !!v || "Email es requerido",
        (v) => /.+@.+/.test(v) || "Email no es valido",
      ],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Nro", value: "nro" },
        { text: "Tipo", value: "tipo" },
        { text: "Perfil / Cliente", value: "cliente" },
        { text: "Fecha", value: "fecha" },
        { text: "Moneda de envio", value: "monenvio" },
        { text: "Monto enviado", value: "montodesde", align: "end" },
        { text: "Moneda de recepcion", value: "monrecepcion" },
        {
          text: "Monto que debes enviar",
          value: "montohacia",
          align: "end",
          class: "blue--text",
        },
        { text: "tipo Cambio", value: "tipocambio" },
        { text: "Estado", value: "estado" },
        { text: "Acciones", value: "acciones" },
      ],
      items: [
        "registrado",
        "comprobando",
        "autorizado",
        "cancelado",
        "anulado",
        "expiro",
      ],
      desserts: [],
    };
  },
  computed: {
    ...mapState("operacion", ["operacionesefectivo"]),
    diplayClass() {
      let classString = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "subtitle-2 text-no-wrap justify-center";
          break;
        case "sm":
          classString = "subtitle-1 justify-center";
          break;
        case "md":
          classString = "h5 justify-center";
          break;
        case "lg":
          classString = "h6 justify-center";
          break;
        case "xl":
          classString = "h6 justify-center";
          break;
      }
      return classString;
    },
    computedHeaders() {
      return this.headers.filter((header) => header.text !== "id");
    },
  },
  watch: {
    dialogEditar(val) {
      val || this.cerrar();
    },
  },
  async created() {
    await this.listarTodoOperacionesEfectivo();
    console.log(this.operacionesefectivo)
  },
  methods: {
    ...mapActions("operacion", ["listarTodoOperacionesEfectivo", "editarEstadoEfectivo"]),
    async guardar() {
      this.formulario.cargar = true;
      console.log(this.elem);
      await this.agregar(this.elem);
      await this.listarUltimoTipocambio();
      console.log(this.tipocambioultimo);
      this.formulario.cargar = false;
      this.dialogoAbrir = false;
    },
    getColor(estado) {
      if (estado == "registrado") return "blue";
      else if (estado == "comprobando") return "cyan";
      else if (estado == "autorizado") return "green accent-4";
      else if (
        estado == "cancelado" ||
        estado == "anulado" ||
        estado == "expiro"
      )
        return "red";
      else return "blue";
    },
    getSelectionStart(o) {
      if (o.createTextRange) {
        var r = document.selection.createRange().duplicate();
        r.moveEnd("character", o.value.length);
        if (r.text == "") return o.value.length;
        return o.value.lastIndexOf(r.text);
      } else return o.selectionStart;
    },
    editItem(item) {
      this.elem.verificacion = item.verificacion;
      this.elem.estado = item.estado;
      this.elem.id = item.id;
      this.elem.nro = item.nro;
      this.elem.nombre = item.cliente.split(" ")[0];
      this.elem.email = item.email;
      this.dialogEditar = true;
    },
    verProcedencia(item) {
      this.procedencia = item.procedencia;
      this.dialogVerProcedencia = true;
    },
    cerrar() {
      this.dialogEditar = false;
    },
    cerrarVerProcedencia() {
      this.dialogVerProcedencia = false;
    },
    async modificar() {
      this.validar = false;
      this.loading = true;
      if (this.elem.verificacion == undefined) {
        this.elem.verificacion = "0";
      }
      await this.editarEstadoEfectivo(this.elem);
      await this.listarTodoOperacionesEfectivo();
      this.loading = false;
      this.validar = true;
      this.cerrar();
    },
    imprimirOperacion(item){
      const date = new Date();

      const formatData = (input) => {
        if (input > 9) {
          return input;
        } else return `0${input}`;
      };

      const formatHour = (input) => {
        if (input > 12) {
          return input - 12;
        }
        return input;
      };

      const format = {
        dd: formatData(date.getDate()),
        mm: formatData(date.getMonth() + 1),
        yyyy: date.getFullYear(),
        HH: formatData(date.getHours()),
        hh: formatData(formatHour(date.getHours())),
        MM: formatData(date.getMinutes()),
        SS: formatData(date.getSeconds()),
      };

      let fechahora = ''

      const format24Hour = ({ dd, mm, yyyy, HH, MM, SS }) => {
        fechahora =
          `${mm}/${dd}/${yyyy} ${HH}:${MM}:${SS}`
      
      };
      format24Hour(format)

      //console.log(item  )
      var doc = new jsPDF("p");
      let pdfName = "Operacion - " + item.nro;

      doc.setFillColor(232, 230, 230);
      doc.rect(10, 10, 190, 10, 'FD'); 
      doc.setFontSize(10).text("DECLARACIÓN JURADA DE CONOCIMIENTO DEL CLIENTE BAJO EL RÉGIMEN GENERAL", 30.5, 16.5);
      doc.setFontSize(8).text(`Fecha de reporte: ${fechahora}`, 155, 5);
      //doc.setLineWidth(0.01).line(0.5, 1.1, 7.0, 1.1);

      doc.setFillColor(255,255,255);
      doc.rect(10, 20, 190, 7, 'FD'); 
      doc.setFontSize(10).text("En el presente documento, el cliente declara bajo juramento, lo siguiente:", 10.5, 24.5);
      
      //fila
      doc.setFillColor(255,255,255);
      doc.rect(10, 27, 95, 7, 'FD'); 
      doc.setFontSize(10).text("Nombres: " + item.cnombres.toUpperCase(), 10.5, 31.5);

      doc.setFillColor(255,255,255);
      doc.rect(105, 27, 95, 7, 'FD'); 
      doc.setFontSize(10).text("Apellidos: " + item.capaellidos.toUpperCase(), 105.5, 31.5);

      //fila
      doc.setFillColor(255,255,255);
      doc.rect(10, 34, 120, 7, 'FD'); 
      doc.setFontSize(10).text("Tipo de documento: " + item.ctipodocumento.toUpperCase(), 10.5, 38.5);

      doc.setFillColor(255,255,255);
      doc.rect(130, 34, 70, 7, 'FD'); 
      doc.setFontSize(10).text("Número de documento: " + item.cnrodocumento.toUpperCase(), 130.5, 38.5);

      //fila
      doc.setFillColor(255,255,255);
      doc.rect(10, 41, 190, 7, 'FD'); 
      doc.setFontSize(10).text("Nacionalidad: PERÚ", 10.5, 45.5);

      //fila
      doc.setFillColor(255,255,255);
      doc.rect(10, 48, 190, 7, 'FD'); 
      doc.setFontSize(10).text("Domicilio: " + item.cdireccion.toUpperCase(), 10.5, 52.5);

      //fila
      doc.setFillColor(255,255,255);
      doc.rect(10, 55, 63.33, 7, 'FD'); 
      doc.setFontSize(10).text("Distrito: " + item.distrito.toUpperCase(), 10.5, 59.5);

      doc.setFillColor(255,255,255);
      doc.rect(73.33, 55, 63.33, 7, 'FD'); 
      doc.setFontSize(10).text("Provincia: " + item.provincia.toUpperCase(), 73.83, 59.5);

      doc.setFillColor(255,255,255);
      doc.rect(136.66, 55, 63.33, 7, 'FD'); 
      doc.setFontSize(10).text("Departamento: " + item.provincia.toUpperCase(), 137.16, 59.5);

      //fila
      doc.setFillColor(255,255,255);
      doc.rect(10, 62, 190, 7, 'FD'); 
      doc.setFontSize(10).text("Ocupación: " + item.ocupacion.toUpperCase(), 10.5, 66.5);
      
      //fila
      doc.setFillColor(255,255,255);
      doc.rect(10, 69, 60, 7, 'FD'); 
      doc.setFontSize(10).text("Celular: " + item.celular.toUpperCase(), 10.5, 73.5);

      doc.setFillColor(255,255,255);
      doc.rect(70, 69, 130, 7, 'FD'); 
      doc.setFontSize(10).text("Correo electrónico: " + item.email, 70.5, 73.5);

      //fila
      doc.setFillColor(255,255,255);
      doc.rect(10, 76, 190, 7, 'FD'); 
      doc.setFontSize(10).text("Propósito de la relación con el sujeto obligado: COMERCIAL", 10.5, 80.5);

      let altoRect = 76;
      let altoText = 80.5;

      //fila
      altoRect = altoRect + 7;
      altoText = altoText + 7;
      doc.setFillColor(255,255,255);
      doc.rect(10, altoRect, 190, 7, 'FD'); 
      doc.setFontSize(10).text("Indicar si es PEP: " + item.pep, 10.5, altoText);

      if (item.pep == "SI"){
        altoRect = altoRect + 7;
        altoText = altoText + 7;
        doc.setFillColor(255,255,255);
        doc.rect(10, altoRect, 95, 7, 'FD'); 
        doc.setFontSize(10).text("Cargo: " + item.cargo, 10.5, altoText)

        doc.setFillColor(255,255,255);
        doc.rect(105, altoRect, 95, 7, 'FD'); 
        doc.setFontSize(10).text("Nombre de la institución: " + item.institucion, 105.5, altoText)
      }

      //fila
      altoRect = altoRect + 7;
      altoText = altoText + 7;
      doc.setFillColor(232, 230, 230);
      doc.rect(10, altoRect, 190, 7, 'FD'); 
      doc.setFontSize(10).text("IDENTIDAD DEL BENEFICIARIO DE LA OPERACIÓN", 10.5, altoText);

      let afavor = ''
      let afavorCode = ''
      
      if (item.perfil == "c"){
        afavor = 'DE MI MISMO'
        afavorCode = 'DMM'
      } else {
        if(item.ruc.substring(0,2) == "10"){
          afavor = 'DE UN TERCERO PERSONA NATURAL'
          afavorCode = 'TPN'
        } else {
          afavor = "PERSONA JURIDICA"
          afavorCode = 'DPJ'
        }
      }

      //fila
      altoRect = altoRect + 7;
      altoText = altoText + 7;
      doc.setFillColor(255,255,255);
      doc.rect(10, altoRect, 190, 7, 'FD'); 
      doc.setFontSize(10).text("Realizo esta operación a favor de: " + afavor, 10.5, altoText);

      if(afavorCode == 'DMM'){
        if(item.procedencia.replace(/^\s+|\s+$/gm,'') !== ''){
          //fila
          altoRect = altoRect + 7;
          altoText = altoText + 7;
          doc.setFillColor(255,255,255);
          doc.rect(10, altoRect, 190, 15, 'FD'); 
          doc.setFontSize(10).text("Origen de los fondos/activos involucrados en la operación, iguale o supere el umbral:", 10.5, altoText);

          //fila sin rectangulo
          altoRect = altoRect + 7;
          altoText = altoText + 7;
          doc.setFontSize(10).text(item.procedencia.toUpperCase(), 10.5, altoText);
        }
        
      } else if(afavorCode == 'TPN') {
        //fila
        altoRect = altoRect + 7;
        altoText = altoText + 7;
        doc.setFillColor(255,255,255);
        doc.rect(10, altoRect, 190, 7, 'FD'); 
        doc.setFontSize(10).text("Nombres y apellidos del tercero persona natural: " + item.razonsocial, 10.5, altoText);

        //fila
        altoRect = altoRect + 7;
        altoText = altoText + 7;
        doc.setFillColor(255,255,255);
        doc.rect(10, altoRect, 190, 7, 'FD'); 
        doc.setFontSize(10).text("RUC: " + item.ruc, 10.5, altoText);

        if(item.procedencia.replace(/^\s+|\s+$/gm,'') !== ''){
          //fila
          altoRect = altoRect + 7;
          altoText = altoText + 7;
          doc.setFillColor(255,255,255);
          doc.rect(10, altoRect, 190, 15, 'FD'); 
          doc.setFontSize(10).text("Origen de los fondos/activos involucrados en la operación, iguale o supere el umbral:", 10.5, altoText);

          //fila sin rectangulo
          altoRect = altoRect + 7;
          altoText = altoText + 7;
          doc.setFontSize(10).text(item.procedencia.toUpperCase(), 10.5, altoText);
         }
      } else {
        //fila
        altoRect = altoRect + 7;
        altoText = altoText + 7;
        doc.setFillColor(255,255,255);
        doc.rect(10, altoRect, 190, 7, 'FD'); 
        doc.setFontSize(10).text("Denominación o Razón Social: " + item.razonsocial, 10.5, altoText);

        //fila
        altoRect = altoRect + 7;
        altoText = altoText + 7;
        doc.setFillColor(255,255,255);
        doc.rect(10, altoRect, 190, 7, 'FD'); 
        doc.setFontSize(10).text("Número de RUC: " + item.ruc, 10.5, altoText);

        //fila
        altoRect = altoRect + 7;
        altoText = altoText + 7;
        doc.setFillColor(255,255,255);
        doc.rect(10, altoRect, 190, 7, 'FD'); 
        doc.setFontSize(10).text("Nombres y apellidos del representante legal: " + item.replegal, 10.5, altoText);

        //fila
        altoRect = altoRect + 7;
        altoText = altoText + 7;
        doc.setFillColor(255,255,255);
        doc.rect(10, altoRect, 190, 7, 'FD'); 
        doc.setFontSize(10).text("DNI del representante legal: " + item.rpnrodocumento, 10.5, altoText);

        if(item.procedencia.replace(/^\s+|\s+$/gm,'') !== ''){
          //fila
          altoRect = altoRect + 7;
          altoText = altoText + 7;
          doc.setFillColor(255,255,255);
          doc.rect(10, altoRect, 190, 15, 'FD'); 
          doc.setFontSize(10).text("Origen de los fondos/activos involucrados en la operación, iguale o supere el umbral:", 10.5, altoText);

          //fila sin rectangulo
          altoRect = altoRect + 7;
          altoText = altoText + 7;
          doc.setFontSize(10).text(item.procedencia.toUpperCase(), 10.5, altoText);
        }
      }

      //fila
      altoRect = altoRect + 7;
      altoText = altoText + 7;
      doc.setFillColor(232, 230, 230);
      doc.rect(10, altoRect, 190, 7, 'FD'); 
      doc.setFontSize(10).text("DATOS DE LA OPERACIÓN " + item.tipo.toUpperCase(), 10.5, altoText);

      //fila
      altoRect = altoRect + 7;
      altoText = altoText + 7;
      doc.setFillColor(255,255,255);
      doc.rect(10, altoRect, 95, 7, 'FD'); 
      doc.setFontSize(10).text("Codigo de referencia:" + item.nro, 10.5, altoText);

      doc.setFillColor(255,255,255);
      doc.rect(105, altoRect, 95, 7, 'FD'); 
      doc.setFontSize(10).text("Fecha y hora:" + item.fecha, 105.5, altoText);

      //fila
      altoRect = altoRect + 7;
      altoText = altoText + 7;
      doc.setFillColor(255,255,255);
      doc.rect(10, altoRect, 95, 7, 'FD'); 
      doc.setFontSize(10).text("Enviado: " + item.monenvio + " | " + item.montodesde, 10.5, altoText);

      doc.setFillColor(255,255,255);
      doc.rect(105, altoRect, 95, 7, 'FD'); 
      doc.setFontSize(10).text("Recibido:" + item.monrecepcion + " | " + item.montohacia, 105.5, altoText);

      altoRect = altoRect + 9;
      altoText = altoText + 9;
      let splitTitle = doc.splitTextToSize('1. El cliente afirma y ratifica todo lo manifestado en la presente declaración jurada, de acuerdo a los terminos y condiciones aceptados al momento de registrar su cuenta de usuario.', 190);
      doc.setFontSize(10).text(splitTitle, 10.5, altoText);

      altoRect = altoRect + 9;
      altoText = altoText + 9;
      splitTitle = doc.splitTextToSize('2. El cliente esta debidamente registrado con un usuario (correo electrónico) y una constraseña (encriptada), esta contraseña es inviolable, solo el usuario registrado con su cuenta de correo electrónico puede cambiar y/o recuperar su contraseña. Toda operación de cambio de dinero se realiza en un ambiente seguro, de acuerdo a las credenciales ingresadas por el cliente en el formulario de inicio de sesión.', 190);
      doc.setFontSize(10).text(splitTitle, 10.5, altoText);

      altoRect = altoRect + 20;
      altoText = altoText + 20;
      splitTitle = doc.splitTextToSize('Nota: Este documento se conserva por el sujeto obligado y, en su caso exhibida a solicitud de la UIF-Perú en actividades de supervisión. No se envía a la UIF-Perú, salvo solicitud expresa.', 230);
      doc.setFontSize(8).text(splitTitle, 10.5, altoText);


      doc.save(pdfName + ".pdf");
    }
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>