<template>
  <div>
    <div>
      <v-btn depressed color="primary" @click="crearPDF"> Primary </v-btn>
    </div>
    <v-data-table
      :headers="computedHeaders"
      :items="empresas"
      sort-by="id"
      class="elevation-1"
      :items-per-page="5"
      :footer-props="{
        'items-per-page-options': [5, 10, -1],
        'items-per-page-text': 'Por pagina',
        'items-per-page-all-text': 'Todo',
      }"
      ref="myTable"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogEditar" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Actualizar datos del cliente</span>
            </v-card-title>
            <v-card-text class="pb-0">
              <v-form ref="formCliente" v-model="formulario.validar">
                <v-container>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-row>
                        <v-col cols="12" md="6" class="pb-0">
                          <v-select
                            v-model="elem.tipodocumento"
                            :items="tipos"
                            label="Tipo de documento"
                            :rules="[
                              (v) => !!v || 'Seleccione el tipo de documento',
                            ]"
                            outlined
                          ></v-select>
                        </v-col>

                        <v-col cols="12" class="pb-0" md="6">
                          <v-text-field
                            v-model="elem.nrodocumento"
                            label="Número de documento"
                            :rules="[(v) => !!v || 'El número es requerido']"
                            outlined
                            required
                            ref="focusdocumento"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        v-model="elem.nombres"
                        label="Nombres"
                        :rules="[(v) => !!v || 'El nombre es requerido']"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="pb-0 pt-0">
                      <v-text-field
                        v-model="elem.apaterno"
                        label="Apellido Paterno"
                        :rules="[
                          (v) => !!v || 'El apellidos parterno es requerido',
                        ]"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="pb-0 pt-0">
                      <v-text-field
                        v-model="elem.amaterno"
                        label="Apellido Materno"
                        :rules="[
                          (v) => !!v || 'El apellido materno es requerido',
                        ]"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="pb-0 pt-0">
                      <v-text-field
                        v-model="elem.direccion"
                        label="Dirección"
                        :rules="[(v) => !!v || 'su direccion es requerida']"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="pb-0 pt-0">
                      <v-text-field
                        v-model="elem.email"
                        :rules="emailReglas"
                        label="Email"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" class="pb-0 pt-0">
                      <v-text-field
                        v-model="elem.celular"
                        label="Celular"
                        :rules="[
                          (v) => !!v || 'Su numero de contacto es requerido',
                        ]"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0 pt-0">
                      <v-text-field
                        v-model="elem.nacionalidad"
                        label="Nacionalidad"
                        :rules="[
                          (v) => !!v || 'El pais de nacionalidad es requerido',
                        ]"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0 pt-0">
                      <v-select
                        v-model="elem.pep"
                        :items="peps"
                        label="PEP"
                        :rules="[(v) => !!v || 'Seleccione si es o no es PEP']"
                        outlined
                      >
                        <template v-slot:prepend>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                color="primary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >
                                mdi-help-circle-outline
                              </v-icon>
                            </template>
                            <div max-width="500">
                              Personas Expuestas Políticamente son las personas
                              naturales, <br />
                              nacionales o extranjeras, que cumplen o que en los
                              últimos cinco (5) años han cumplido <br />
                              funciones públicas destacadas o funciones
                              prominentes en una organización internacional.
                            </div>
                            <span> </span>
                          </v-tooltip>
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" class="pb-0 pt-0">
                      <v-select
                        v-model="elem.ocupacion"
                        :items="ocupaciones"
                        label="Ocupación"
                        :rules="[(v) => !!v || 'La ocupación es requerida']"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cerrar"> Salir </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!validar"
                :loading="loading"
                @click="modificar"
              >
                Modificar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.contrasena`]="{ item }">
        <div class="text-truncate" style="max-width: 80px">
          {{ item.contrasena }}
        </div>
      </template>
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
      </template>
      <template
        v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
      </template>
      <template v-slot:no-data> No hay registros </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import jsPDF from "jspdf";
import "jspdf-autotable";

export default {
  name: "listatodoempresas",
  components: {},
  data() {
    return {
      loading: false,
      validar: true,
      dialogEditar: false,
      formulario: {
        validar: true,
        cargar: false,
      },
      elem: {
        id: "",
        nombres: "",
        apaterno: "",
        amaterno: "",
        direccion: "",
        email: "",
        celular: "",
        tipodocumento: "",
        nrodocumento: "",
        nacionalidad: "",
        pep: "",
        ocupacion: "",
        habilitado: "",
        contrasena: "",
        contrasena2: "",
      },
      emailReglas: [
        (v) => !!v || "Email es requerido",
        (v) => /.+@.+/.test(v) || "Email no es valido",
      ],
      tipos: [
        "DNI",
        "RUC",
        "CARNET EXTRANJERIA",
        "CÉDULA CIUDADANA",
        "PASAPORTE",
        "CÉDULA DIPLOMATICA",
      ],
      ocupaciones: [
        "Abogado (a)",
        " Administrador(a)",
        "Agricultor(a)",
        "Ama de casa",
        "Arquitecto(a)",
        "Artista",
        "Cambista",
        "Comerciante",
        "Consultor(a)",
        "Contador(a)",
        "Diseñador(a)",
        "Docente",
        "Economista",
        "Empresario(a)",
        "Enfermero(a)",
        "Estudiante",
        "Funcionario Público",
        "Importador o exportador",
        "Ingeniera(o)",
        "Ingeniera(o)",
        "Jubilado",
        "Médico",
        "Militar",
        "Obstetriz",
        "Odontólogo(a)",
        "Periodista",
        "Policía",
        "Psicólogo(a)",
        "Publicista",
        "Seguridad",
        "Trabajador dependiente",
        "Trabajador Independiente",
        "Trabajador(a) Social",
        "Vendedor(a)",
        "Otros",
      ],
      peps: ["SI", "NO"],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Razon social", value: "razonsocial" },
        { text: "RUC", value: "ruc" },
        { text: "dirección", value: "direcion" },
        { text: "Direccion", value: "direccion" },
        { text: "Telefono", value: "telefono" },
        { text: "Fecha registro", value: "fecharegistro" },
        { text: "Tipo de documento (Rep. Legal)", value: "rptipodocumento" },
        { text: "Numero de documento (Rep. Legal)", value: "rpnrodocumento" },
        { text: "Nombres (Rep. Legal)", value: "rpnombres" },
        { text: "Apellido paterno (Rep. Legal)", value: "rpapaterno" },
        { text: "Apellido materno (Rep. Legal)", value: "rpamaterno" },
      ],
      items: ["registrado", "comprobando", "autorizado", "cancelado"],
      desserts: [],
    };
  },
  computed: {
    ...mapState("empresa", ["empresas"]),
    diplayClass() {
      let classString = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "subtitle-2 text-no-wrap justify-center";
          break;
        case "sm":
          classString = "subtitle-1 justify-center";
          break;
        case "md":
          classString = "h5 justify-center";
          break;
        case "lg":
          classString = "h6 justify-center";
          break;
        case "xl":
          classString = "h6 justify-center";
          break;
      }
      return classString;
    },
    computedHeaders() {
      return this.headers.filter((header) => header.text !== "id");
    },
  },
  watch: {
    dialogEditar(val) {
      val || this.cerrar();
    },
  },
  async created() {
    await this.listarEmpresas();
  },
  methods: {
    ...mapActions("empresa", ["listarEmpresas"]),
    async guardar() {
      this.formulario.cargar = true;
      console.log(this.elem);
      await this.agregar(this.elem);
      await this.listarUltimoTipocambio();
      console.log(this.tipocambioultimo);
      this.formulario.cargar = false;
      this.dialogoAbrir = false;
    },
    getColor(estado) {
      if (estado == "registrado") return "blue";
      else if (estado == "comprobando") return "cyan";
      else if (estado == "autorizado") return "green accent-4";
      else if (estado == "cancelado") return "red";
      else return "blue";
    },
    getSelectionStart(o) {
      if (o.createTextRange) {
        var r = document.selection.createRange().duplicate();
        r.moveEnd("character", o.value.length);
        if (r.text == "") return o.value.length;
        return o.value.lastIndexOf(r.text);
      } else return o.selectionStart;
    },
    editItem(item) {
      this.elem.id = item.id;
      this.elem.nombres = item.nombres;
      this.elem.apaterno = item.apaterno;
      this.elem.amaterno = item.amaterno;
      this.elem.direccion = item.direccion;
      this.elem.email = item.email;
      this.elem.celular = item.celular;
      this.elem.tipodocumento = item.tipodocumento;
      this.elem.nrodocumento = item.nrodocumento;
      this.elem.nacionalidad = item.nacionalidad;
      this.elem.ocupacion = item.ocupacion;
      this.elem.pep = item.pep;
      this.elem.contrasena = item.contrasena;
      this.elem.contrasena2 = item.contrasena;
      this.dialogEditar = true;
    },
    cerrar() {
      this.dialogEditar = false;
    },
    async modificar() {
      this.validar = false;
      this.loading = true;
      if (this.elem.verificacion == undefined) {
        this.elem.verificacion = "0";
      }
      //await this.editarCliente(this.elem)
      await this.listarClientes();
      this.loading = false;
      this.validar = true;
      this.cerrar();
    },
    crearPDF() {
      const date = new Date();

      const formatData = (input) => {
        if (input > 9) {
          return input;
        } else return `0${input}`;
      };

      const formatHour = (input) => {
        if (input > 12) {
          return input - 12;
        }
        return input;
      };

      const format = {
        dd: formatData(date.getDate()),
        mm: formatData(date.getMonth() + 1),
        yyyy: date.getFullYear(),
        HH: formatData(date.getHours()),
        hh: formatData(formatHour(date.getHours())),
        MM: formatData(date.getMinutes()),
        SS: formatData(date.getSeconds()),
      };

      let fechahora = ''

      const format24Hour = ({ dd, mm, yyyy, HH, MM, SS }) => {
        fechahora =
          `${mm}/${dd}/${yyyy} ${HH}:${MM}:${SS}`
      
      };

      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = [
        "Razon social",
        "RUC",
        "Direccion",
        "Telefono",
        "Fecha registro",
        "Tipo documento (Rep. Legal)",
        "Numero documento (Rep. Legal)",
        "Nombres (Rep. Legal)",
        "Apellido paterno (Rep. Legal)",
        "Apellido materno (Rep. Legal)",
      ];
      let pdfName = "Empresas";
      source.items.forEach((element) => {
        var temp = [
          element.razonsocial,
          element.ruc,
          element.direcion,
          element.telefono,
          element.fecharegistro,
          element.rptipodocumento,
          element.rpnrodocumento,
          element.rpnombres,
          element.rpapaterno,
          element.rpamaterno,
        ];
        rows.push(temp);
      });
      var doc = new jsPDF("l", "in");

      format24Hour(format)
      
      console.log(fechahora)

      doc.setFontSize(12).text("Lista de empresas", 0.5, 1.0);
      doc.setFontSize(11).text(`Fecha de reporte: ${fechahora}`, 5.5, 1.0);
      doc.setLineWidth(0.01).line(0.5, 1.1, 10.0, 1.1);
      doc.autoTable(columnHeader, rows, { startY: 1.2 });
      doc.save(pdfName + ".pdf");
    },
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>