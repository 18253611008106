import { direccion } from '../_extra/configuracion'

const state = {
    oresultado: {},
    clientes: []
}

const actions = {
    async listarClientes({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/clientes.php?accion=li002`, opciones)
            const contenido = await response.json()
            commit('obtnerClientes', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editarCliente({commit}, elemento) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            //console.log(JSON.stringify(elemento))   
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ed002`, opciones)
            const contenido = await respuesta.json()
            commit('editarClienteModificar', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editarClienteEfectivo({commit}, elemento) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            console.log(JSON.stringify(elemento))   
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ed005`, opciones)
            const contenido = await respuesta.json()
            commit('editarClienteModificarEfectivo', contenido)
        } catch (error) {
            console.error(error)
        }
    }
}

const mutations = {
    obtnerClientes(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.clientes = contenido
    },
    editarClienteModificar(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
    editarClienteModificarEfectivo(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
}

export const cliente = {
    namespaced: true,
    state,
    actions,
    mutations
}