<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <v-divider></v-divider>
        <div class="pa-4 pl-6  "> <span class="rounded amber lighten-2 pa-2 text-body-1 font-weight-medium">Administrar Cuentas</span> </div>
        <v-row class="pa-6 pt-3">
          <v-col cols="12" md="6" sm="6" lg="3" xl="2" >
            <v-hover v-slot="{ hover }">
              <v-expand-transition>
              <v-card class="text-center text-truncate"  :color="hover ? 'orange lighten-5' : 'white'" @click="nuevacuenta" >
                    <v-card-actions>
                    </v-card-actions>
                    <v-icon
                      large
                      color="blue darken-2"
                    >
                      mdi-chart-box-plus-outline
                    </v-icon>
                    <v-card-title :class="diplayClass">Nueva Cuenta</v-card-title>
                    <v-card-text class="text--secondary ">Agregar nueva Cuenta </v-card-text>
              </v-card>
              </v-expand-transition>
            </v-hover>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pt-3">
           <v-col cols="12" md="4" sm="6" lg="3" xl="3" v-for="(item, index) in this.listaCuentas" :key="item.id">
            <v-card
              class="pt-4 ml-5"
              max-width="400"
              color="#E3F2FD"
              
            >
              <v-card-text class="pb-0 pt-0">
                <div class="pa-2 body-1 font-weight-bold ">Cuenta N° {{ index + 1 }}</div>
                    <v-container>
                    <v-row>
                    <v-col cols="12" class="pb-0">
                        <v-row>
                          <v-col
                          cols="12"
                          md="12"
                          class="pb-0"
                          >
                            <v-select
                                v-model="item.banco"
                                :items="tipos"
                                item-text="nombre"
                                item-value="nombre"
                                label="Banco de la cuenta"
                                :rules="[v => !!v || 'Seleccione el Banco de su cuenta']"
                                outlined
                                readonly 
                                class="mb-0"
                            >
                              <template v-slot:item="slotProps">
                                    <img :src="slotProps.item.imagen" :class="['mr-2', 'em']" />
                                    {{slotProps.item.banco}}
                                </template>
                                <template #selection="{ item }">
                                    <img :src="item.imagen" alt="">
                                    <v-chip color="primary">{{item.nombre}}</v-chip>
                                </template>
                            </v-select>
                          </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="pb-3 pt-0 mt-0">
                      <v-btn :class="(item.principal == 'SI') ? 'indigo':'primary' " class="mr-4 white--text mb-3">
                        {{(item.principal == 'SI') ? 'Cuenta principal' : 'Cuenta secundaria'}}
                      </v-btn>
                      <v-btn class="primary" v-if="item.moneda == 'USD'">
                        <img width="22" height="22" class="mr-2" :src="require('@/assets/eeuu.svg')">
                        Dolares
                      </v-btn>
                      <v-btn class="primary" v-else>
                        <img width="22" height="22" class="mr-2" :src="require('@/assets/peru.svg')">
                        Soles
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="12" class="pb-6 pt-0 mt-0">
                      Tipo:
                      <v-btn class="primary">
                         {{ item.tipo }}
                      </v-btn>
                    </v-col>
                    <v-col
                    cols="12"
                    md="12"
                    class="pb-0 pt-0"
                    >
                      <v-text-field
                          v-model="item.nrocuenta"
                          label="Número de cuenta"
                          :rules="[v => !!v || 'Ingrese el número de cuenta']"
                          prepend-inner-icon="mdi-bank"
                          outlined
                          readonly
                          dense
                      ></v-text-field>
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                      class="pb-0 pt-0"
                      >
                      <v-text-field
                          v-model="item.nrocuentainter"
                          label="Número de cuenta interbancaria"
                          :rules="[v => !!v || 'Ingrese el número de cuenta interbancaria']"
                          prepend-inner-icon="mdi-bank-transfer"
                          outlined
                          readonly
                          dense
                      ></v-text-field>
                    </v-col>
                    </v-row>
                    </v-container>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="editarEmpresa(item)">Modificar</v-btn>
            </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
        <cuentabancaria-crear ref="dialogoCuentabancariaCrear" v-on:actualizaListaCuentas="actualizarLista" />
    </v-row>
    <v-row>
        <cuentabancaria-editar ref="dialogoCuentabancariaEditar" />
    </v-row>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'
import CuentabancariaCrear from '../components/cuentabancaria.crear.vue'
import cuentabancariaEditar from '../components/cuentabancaria.editar.vue'

  export default {
    components: {
        CuentabancariaCrear,
        cuentabancariaEditar
    },
    data: () => ({
      formulario : {
        validar: true,
        cargar: false
      },
      elem : {
        banco: '',
        nrocuenta: '',
        nrocuentainter: '',
        moneda: '',
        principal: ''
      },
      listaCuentas: [],
      emailReglas: [
        v => !!v || 'Email es requerido',
        v => /.+@.+/.test(v) || 'Email no es valido',
      ],
      tipos: [
        { nombre : 'BCP', imagen : require('@/assets/bcp.png') },
        { nombre : 'Interbank', imagen : require('@/assets/interbank.png') },
        { nombre : 'Scotiabank', imagen : require('@/assets/scotiabank.png') },
        { nombre : 'BBVA', imagen : require('@/assets/bbva.png') },
        { nombre : 'Pichincha', imagen : require('@/assets/pichincha.png') },
        { nombre : 'Banbif', imagen : require('@/assets/banbif.png') },
        { nombre : 'Mibanco', imagen : require('@/assets/mibanco.png') },
        { nombre : 'Ripley', imagen : require('@/assets/ripley.png') },
       ]
    }),
    async created() {
      await this.llenarCuentas()
      
    },
    computed: {
      ...mapState('cuentausuario', ['cuentausuarios']),
      diplayClass() {
        let classString = ''
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            classString = 'subtitle-2 text-no-wrap justify-center'
            break
          case 'sm':
            classString = 'subtitle-1 justify-center'
            break
          case 'md':
            classString = 'h5 justify-center'
            break
          case 'lg':
            classString = 'h6 justify-center'
            break
          case 'xl':
            classString = 'h6 justify-center'
            break
        }
        return classString
      }
    },
    methods: {
      ...mapActions('cuentausuario', ['listarCuentas', 'listarCuentaPrincipal', 'listarCuentaBancoMoneda','asignarCuentausuarioEditar']),
      async llenarCuentas(){
        await this.listarCuentas()
        this.listaCuentas = this.cuentausuarios
      },
      nuevacuenta() {
         this.$refs.dialogoCuentabancariaCrear.mostrarDialogo()
      },
      editarEmpresa(ele) {
        this.asignarCuentausuarioEditar(ele)
        this.$refs.dialogoCuentabancariaEditar.mostrarDialogo()
      },
      actualizarLista(value){
        this.listaCuentas = value
      }
    }
  }
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
.hidden-sm-and-down{
  color: white !important;
}
</style>