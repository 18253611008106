var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.computedHeaders,"items":_vm.operaciones,"items-per-page":5,"disable-sort":"","footer-props":{
      'items-per-page-options': [5, 10, -1],
      'items-per-page-text': 'Por pagina',
      'items-per-page-all-text': 'Todo',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.dialogEditar),callback:function ($$v) {_vm.dialogEditar=$$v},expression:"dialogEditar"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Modificar cambio")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Codigo de verificacion"},model:{value:(_vm.elem.verificacion),callback:function ($$v) {_vm.$set(_vm.elem, "verificacion", $$v)},expression:"elem.verificacion"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Estado"},scopedSlots:_vm._u([{key:"item",fn:function(slotProps){return [_c('v-chip',{attrs:{"color":_vm.getColor(slotProps.item),"dark":""}},[_vm._v(" "+_vm._s(slotProps.item)+" ")])]}},{key:"selection",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item),"dark":""}},[_vm._v(_vm._s(item))])]}}]),model:{value:(_vm.elem.estado),callback:function ($$v) {_vm.$set(_vm.elem, "estado", $$v)},expression:"elem.estado"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cerrar}},[_vm._v(" Salir ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":!_vm.validar,"loading":_vm.loading},on:{"click":_vm.modificar}},[_vm._v(" Modificar ")])],1)],1)],1)]},proxy:true},{key:"item.nro",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.nro))])]}},{key:"item.cliente",fn:function(ref){
    var item = ref.item;
return [_c('div',[(item.perfil == 'c')?_c('v-chip',{staticClass:"ml-0 mr-0 black--text",attrs:{"color":"blue lighten-4","label":"","small":""}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-account ")]),_vm._v(" Persona ")],1):_c('v-chip',{staticClass:"ml-0 mr-0 black--text",attrs:{"color":"green lighten-4","label":"","small":""}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v(" mdi-domain ")]),_vm._v(" Empresa ")],1),_c('div',{staticClass:"font-weight-medium mt-1"},[_vm._v(_vm._s(item.cliente))])],1)]}},{key:"item.desde",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('img',{class:['mr-2', 'em', 'cuentas-size'],attrs:{"src":require('@/assets/' + item.monenvio.toLowerCase() + '-flag.svg')}}),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.bancodesde))]),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.cuentadesde))])])]}},{key:"item.montohacia",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('span',{staticClass:"font-weight-medium blue--text"},[_vm._v(_vm._s(item.montohacia))])])]}},{key:"item.hacia",fn:function(ref){
    var item = ref.item;
return [_c('div',[_c('img',{class:['mr-2', 'em', 'cuentas-size'],attrs:{"src":require('@/assets/' +
              item.monrecepcion.toLowerCase() +
              '-flag.svg')}}),_c('span',{staticClass:"font-weight-medium blue--text"},[_vm._v(_vm._s(item.bancohacia))]),_c('br'),_c('span',{staticClass:"font-weight-medium blue--text"},[_vm._v(_vm._s(item.cuentahacia))])])]}},{key:"item.llego",fn:function(ref){
              var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.bancollego)+" | "+_vm._s(item.monedallego)+" "),_c('img',{class:['mr-2', 'em', 'cuentas-size'],attrs:{"src":require('@/assets/' +
              item.monedallego.toLowerCase() +
              '-flag.svg')}}),_c('br'),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(item.cuentallego))])])]}},{key:"item.estado",fn:function(props){return [_c('v-chip',{attrs:{"color":_vm.getColor(props.item.estado),"dark":""}},[_vm._v(" "+_vm._s(props.item.estado)+" ")])]}},{key:"item.acciones",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red lighten-1"},on:{"click":function($event){return _vm.imprimirOperacion(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-pdf-box ")])]}}],null,true)},[_c('span',[_vm._v("Imprimir")])])]}},{key:"footer.page-text",fn:function(ref){
              var pageStart = ref.pageStart;
              var pageStop = ref.pageStop;
              var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No hay registros ")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }