<template>
  <v-app>
    <Barmenu v-show="estado.logueado"/>
    <v-main style="padding: 0px;">
        <router-view />
    </v-main>
  </v-app>
</template>

<script>

import { mapState } from 'vuex'

import Barmenu from './components/Barmenu';

export default {
  name: 'App',

  components: {
    Barmenu
  },

  data: () => ({
    logueado:false
    //
  }),
  computed: {
    ...mapState('usuario', ['estado'])
  }
};
</script>
