import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    empresacliente: [],
    empresas: [],
    empresaEditar: []
}

const actions = {
    async listarEmpresas({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/empresas.php?accion=li003`, opciones)
            const contenido = await response.json()
            commit('obtnerEmpresas', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarEmpresasPorCliente({commit}, idcliente) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/empresa.php?idcliente=${idcliente}`, opciones)
            const contenido = await response.json()
            commit('obtenerEmpresaCliente', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async agregar({commit}, elementoAgregar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoAgregar)
        }
        try {
            let respuesta = await fetch(`${direccion().url}/empresa.php`, opciones)
            const contenido = await respuesta.json()
            commit('agregarEmpresa', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editar({commit}, elementoEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoEditar)
        }
        try {
            console.log(elementoEditar.id)
            await fetch(`${direccion().url}/empresa.php?id=${elementoEditar.id}`, opciones)
            commit('editarEmpresa', elementoEditar);
        } catch (error) {
            console.error(error)
        }
    },
    asignarEmpresaEditar({commit}, elementoEmpresa) {
        try {
            commit('asignarEmpresa', elementoEmpresa)
        } catch (error) {
            console.error(error)
        }
    }
}

const mutations = {
    obtenerEmpresaCliente(state, contenido) {
        state.all = {resultado : contenido}
        state.empresacliente = contenido
    },
    agregarEmpresa(state, contenido) {
        state.all = {resultado : contenido}
    },
    asignarEmpresa(state, contenido) {
        state.empresaEditar = contenido
    },
    editarEmpresa(state, contenido) {
        state.empresaEditar = contenido
    }, obtnerEmpresas(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.empresas = contenido
    },
}

export const empresa = {
    namespaced: true,
    state,
    actions,
    mutations
}