<template>
  <div class="login">
    <v-container class="fill-height" fluid>
      <v-row class="mt-10 pt-5" justify="center">
        <v-col cols="12" sm="12" class="text-center">
          <v-card class="elevation-12 ma-auto" max-width="400">
            <div class="d-flex py-5 px-5 justify-center">
              <v-img
                class="white--text align-center text-center"
                height="80px"
                max-width="256"
                src="@/assets/logo-cambio24.png"
              >
              </v-img>
            </div>

            <v-card-title class="cyan d-flex justify-center white--text"
              >Iniciar sesión - administrador</v-card-title
            >
            <v-form
              ref="form"
              v-model="validar"
              @submit.prevent="iniciarsesion"
              id="loginform"
            >
              <v-card-text>
                <v-text-field
                  label="correo"
                  v-model="correo"
                  :rules="correoReglas"
                  prepend-icon="mdi-account"
                  type="text"
                  v-on:click="onChange"
                ></v-text-field>
                <v-text-field
                  v-model="contrasena"
                  label="Contraseña"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="contrasenaReglas"
                  :type="show1 ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  name="input-10-1"
                  counter
                  @click:append="show1 = !show1"
                >
                </v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn type="submit" form="loginform" color="primary"
                  >Iniciar</v-btn
                >
              </v-card-actions>
            </v-form>
            <v-alert v-if="alerta.message" :type="alerta.type">
              {{ alerta.message }}
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      show1: false,
      validar: true,
      correo: "",
      correoReglas: [(v) => !!v || "Ingrese el E-mail"],
      contrasena: "",
      contrasenaReglas: [(v) => !!v || "Ingrese la contraseña"],
    };
  },
  computed: {
    ...mapState({ alerta: (state) => state.alerta }),
    ...mapState("usuario", ["estado", "usuarios"]),
  },
  created() {
    console.log("aqui");
  },
  methods: {
    ...mapActions("usuario", ["login", "logout"], {
      clearAlert: "alert/clear",
    }),
    iniciarsesion() {
      if (this.$refs.form.validate()) {
        const { correo, contrasena } = this;
        if (correo && contrasena) {
          this.login({ correo, contrasena });
        }
      }
    },
    onChange() {
      this.alerta.message = null;
    },
  },
  watch: {},
};
</script>