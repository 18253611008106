import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Perfil from '../views/Perfil.vue'
import cuentas_usuario from '../views/Cuentas-usuario.vue'
import operaciones from '../views/Operaciones.vue'
import clientes from '../views/Clientes.vue'
import empresas from '../views/Empresas.vue'
import store from '../store/index'
import operaciones_efectivo from '../views/Operaciones-en-efectivo.vue'
import tipo_cambio_efectivo from '../views/Tipo-cambio-efectivo.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/cuentas-usuario',
    name: 'cuentas-usuario',
    component: cuentas_usuario
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/operaciones',
    name: 'operaciones',
    component: operaciones
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/clientes',
    name: 'clientes',
    component: clientes
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/empresas',
    name: 'empresas',
    component: empresas
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/operaciones-en-efectivo',
    name: 'operaciones-en-efectivo',
    component: operaciones_efectivo
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/tipo-cambio-efectivo',
    name: 'tipo-cambio-efectivo',
    component: tipo_cambio_efectivo
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path)
  let logueado = localStorage.getItem('usuario')
  if(logueado)
  {
    let clientelogueado = JSON.parse(logueado)
    store.dispatch('checkLogin', clientelogueado.token )
  }
  logueado = localStorage.getItem('usuario')
  //store.dispatch(llamarhola)
  //console.log(">>: " + !logueado + " >>: " + authRequired)
  if (!logueado && authRequired) {
    //console.log('aqui 1: ' + to.fullPath)
      next({
        path: '/login'
      })
  }else {
    //console.log('aqui 2: ' + to.fullPath)
    next()
  }
})

export default router
