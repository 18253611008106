<template>
    <v-dialog
        v-model="dialogoAbrir"
        max-width="450px"
    >
        <v-card>
            <v-card-title>
                <span class="headline">Nueva Cuenta</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="formCuenta" v-model="formulario.validar">
                    <v-container>
                    <v-row>
                    <v-col cols="12" class="pb-0">
                        <v-row>
                          <v-col
                          cols="12"
                          md="12"
                          >
                            <v-select
                                v-model="elem.banco"
                                :items="tipos"
                                item-text="nombre"
                                item-value="nombre"
                                label="Banco de la cuenta"
                                :rules="[v => !!v || 'Seleccione el Banco de su cuenta']"
                                ref="FocusOrderDelivery"
                            >
                              <template v-slot:item="slotProps">
                                    <img :src="slotProps.item.imagen" :class="['mr-2', 'em']" />
                                    {{slotProps.item.nombre}}
                                </template>
                                <template #selection="{ item }">
                                    <img :src="item.imagen" alt="">
                                    <v-chip color="blue lighten-4">{{item.nombre}}</v-chip>
                                </template>
                            </v-select>
                          </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="pb-0 pt-0">
                      <p class="mb-1">Moneda</p>
                      <v-btn-toggle
                        v-model="elem.moneda"
                        color="primary"
                        mandatory
                      >
                        <v-btn value="PEN">
                        <img width="24" height="24" class="mr-2" :src="require('@/assets/peru.svg')">
                          Soles
                        </v-btn>
                        <v-btn value="USD">
                        <img width="24" height="24" class="mr-2" :src="require('@/assets/eeuu.svg')">
                          Dolares
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                        <v-col
                        cols="12"
                        md="12"
                        class="pb-0"
                        >
                        <v-text-field
                            v-model="elem.nrocuenta"
                            label="Número de cuenta"
                            :rules="[v => !!v || 'Ingrese el número de cuenta']"
                            prepend-inner-icon="mdi-bank"
                            required
                        ></v-text-field>
                        </v-col>

                        <v-col
                        cols="12"
                        md="12"
                        class="pb-0"
                        >
                        <v-text-field
                            v-model="elem.nrocuentainter"
                            label="Número de cuenta interbancaria"
                            :rules="[v => !!v || 'Ingrese el número de cuenta interbancaria']"
                            prepend-inner-icon="mdi-bank-transfer"
                            required
                        ></v-text-field>
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        class="pb-0"
                        >
                            <v-select
                            v-model="elem.tipo"
                            :items="cuentastipo"
                            item-text="nombre"
                            item-value="valor"
                            label="Tipo de cuenta"
                            :rules="[v => !!v || 'Seleccione el tipo de cuenta']"
                            ></v-select>
                        </v-col>
                        <v-col
                        cols="12"
                        md="6"
                        class="pb-0"
                        >
                            <v-select
                            v-model="elem.principal"
                            :items="opcionesPrincipal"
                            item-text="nombre"
                            item-value="valor"
                            label="¿Es cuenta principal?"
                            :rules="[v => !!v || 'Seleccione si es cuenta principal']"
                            ></v-select>
                        </v-col>
                    </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" :disabled="!formulario.validar" :loading="formulario.cargar" @click="guardar">Guardar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {mapState, mapActions} from 'vuex'
  export default {
    name: "cuentabancaria.crear",
    data: () => ({
      dialogoAbrir: false,
      formulario : {
        validar: true,
        cargar: false
      },
      elem : {
        banco: '',
        nrocuenta: '',
        nrocuentainter: '',
        moneda: '',
        principal: '',
        tipo: ''
      },
      emailReglas: [
        v => !!v || 'Email es requerido',
        v => /.+@.+/.test(v) || 'Email no es valido',
      ],
      tipos: [
        { nombre : 'BCP', imagen : require('@/assets/bcp.png') },
        { nombre : 'Interbank', imagen : require('@/assets/interbank.png') },
        { nombre : 'Scotiabank', imagen : require('@/assets/scotiabank.png') },
        { nombre : 'BBVA', imagen : require('@/assets/bbva.png') },
        { nombre : 'Pichincha', imagen : require('@/assets/pichincha.png') },
        { nombre : 'Banbif', imagen : require('@/assets/banbif.png') },
        { nombre : 'Mibanco', imagen : require('@/assets/mibanco.png') },
        { nombre : 'Ripley', imagen : require('@/assets/ripley.png') },
       ],
       opcionesPrincipal: [
           {nombre: 'Si' , valor: 'SI'}, {nombre: 'No', valor: 'NO'}
       ],
       cuentastipo: [
           {nombre: 'Ahorros' , valor: 'AHORROS'}, {nombre: 'Corriente', valor: 'CORRIENTE'}
       ]
    }),
    created() {

    },
    computed: {
        ...mapState('cuentausuario', ['all', 'cuentausuarios']),
    },
    methods: {
        ...mapActions('cuentausuario', ['agregar', 'listarCuentas']),
        async mostrarDialogo() {
            if(this.$refs.formCuenta) {
                this.$refs.formCuenta.reset()
            }
            
            this.dialogoAbrir = true
            //console.log(this.$refs)
            //this.$refs.FocusOrderDelivery.focus();
            setTimeout(() => {
                this.$refs.FocusOrderDelivery.focus()
            }, 200)
            //await this.listarEmpresasPorCliente(this.clientesele.id)
        },
        async guardar() {
            this.formulario.cargar = true
            //console.log(this.elem)
            await this.agregar(this.elem)
            await this.listarCuentas()
            this.$emit('actualizaListaCuentas', this.cuentausuarios)
            this.formulario.cargar = false
            this.dialogoAbrir = false
            this.$refs.formCuenta.reset()
        }
    }
  }
</script>