import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    tipocambio: [],
    tipocambioultimo: [],
    tipocambioefectivo: [],
    tipocambioultimoefectivo: []
}

const actions = {
    async listarTipocambio({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/tipocambio.php`, opciones)
            console.log(response)
            const contenido = await response.json()
            commit('obtenerTipocambio', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarUltimoTipocambio({commit}, fecha) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/tipocambio.php?fecha=${fecha}`, opciones)
            const contenido = await response.json()
            commit('obtenerUltimoTipocambio', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async agregar({commit}, elementoAgregar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoAgregar)
        }
        try {
            let respuesta = await fetch(`${direccion().url}/tipocambio.php`, opciones)
            const contenido = await respuesta.json()
            commit('agregarTipocambio', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarUltimoTipocambioEfectivo({commit}, fecha) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/tipocambio.php?fechaefectivo=${fecha}`, opciones)
            const contenido = await response.json()
            commit('obtenerUltimoTipocambioEfectivo', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async agregarEfectivo({commit}, elementoAgregar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoAgregar)
        }
        try {
            let respuesta = await fetch(`${direccion().url}/tipocambio.php?accion=a0001`, opciones)
            const contenido = await respuesta.json()
            commit('agregarTipocambioEfectivo', contenido)
        } catch (error) {
            console.error(error)
        }
    }
}

const mutations = {
    obtenerTipocambio(state, contenido) {
        state.all = {resultado : contenido}
        state.tipocambio = contenido
    },
    obtenerUltimoTipocambio(state, contenido) {
        state.all = {resultado : contenido}
        state.tipocambioultimo = contenido
    },
    agregarTipocambio(state, contenido) {
        state.all = {resultado : contenido}
    },
    obtenerUltimoTipocambioEfectivo(state, contenido) {
        state.all = {resultado : contenido}
        state.tipocambioultimoefectivo = contenido
    },
    agregarTipocambioEfectivo(state, contenido) {
        state.all = {resultado : contenido}
    }
}

export const tipocambio = {
    namespaced: true,
    state,
    actions,
    mutations
}