<template>
  <div class="home">
    <v-row class="px-5">
      <v-col cols="12" md="8" sm="12" lg="8" xl="7">
        <v-row class="pa-5">
          <v-col cols="12" md="6" sm="6" lg="6" xl="6" >
            <div class="pa-4 pl-0 pt-1 "> <span class="rounded grey lighten-2 pa-2 text-body-1 font-weight-medium">Actualizar Tipo de cambio (Efectivo)</span> </div>
            <v-card>
              <v-card-title>
              </v-card-title>
              <v-card-text>
                  <v-form ref="formTipocambio" v-model="formulario.validar">
                      <v-container>
                      <v-row>
                          <v-col
                          cols="12"
                          md="12"
                          class="pb-0"
                          >
                          <v-text-field
                              v-model="elem.compra"
                              label="Compra"
                              :rules="[v => !!v || 'Ingrese el valor de la compra']"
                              prepend-inner-icon="mdi-cash-usd"
                              @keypress="validarDecimal($event.target, $event)"
                              required
                              outlined
                          ></v-text-field>
                          </v-col>
                          <v-col
                          cols="12"
                          md="12"
                          class="pb-0"
                          >
                          <v-text-field
                              v-model="elem.venta"
                              label="Venta"
                              :rules="[v => !!v || 'Ingrese el valor de la Venta']"
                              @keypress="validarDecimal($event.target, $event)"
                              prepend-inner-icon="mdi-cash-usd-outline"
                              required
                              outlined
                          ></v-text-field>
                          </v-col>
                      </v-row>
                      </v-container>
                  </v-form>
              </v-card-text>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" :disabled="!formulario.validar" :loading="formulario.cargar" @click="guardar">Actualizar</v-btn>
              </v-card-actions>
          </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
export default {
  name: 'tipo-cambio-efectivo',
  components: {
  },
  data () {
    return {
      formulario : {
        validar: true,
        cargar: false
      },
      elem : {
        compra: '',
        venta: ''
      },
      emailReglas: [
        v => !!v || 'Email es requerido',
        v => /.+@.+/.test(v) || 'Email no es valido',
      ],
      headers: [
        {
          text: 'Dessert (100g serving)',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Calories', value: 'calories' },
        { text: 'Fat (g)', value: 'fat' },
        { text: 'Carbs (g)', value: 'carbs' },
        { text: 'Protein (g)', value: 'protein' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    ...mapState('tipocambio', ['tipocambioultimoefectivo']),
     diplayClass() {
      let classString = ''
      console.log(this.$vuetify.breakpoint.name)
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          classString = 'subtitle-2 text-no-wrap justify-center'
          break
        case 'sm':
          classString = 'subtitle-1 justify-center'
          break
        case 'md':
          classString = 'h5 justify-center'
          break
        case 'lg':
          classString = 'h6 justify-center'
          break
        case 'xl':
          classString = 'h6 justify-center'
          break
      }
      return classString
    }
  },
  async created() {
    await this.listarUltimoTipocambioEfectivo('2024-01-01')
    this.elem.compra = this.tipocambioultimoefectivo[0].compra
    this.elem.venta = this.tipocambioultimoefectivo[0].venta
  },
  methods: {
    ...mapActions('tipocambio', ['listarUltimoTipocambioEfectivo', 'agregarEfectivo']),
    async guardar(){
      this.formulario.cargar = true
      await this.agregarEfectivo(this.elem)
      await this.listarUltimoTipocambioEfectivo()
      this.formulario.cargar = false
      this.dialogoAbrir = false
    },
    validarDecimal(elemento, evt) {
        let el = elemento
        var charCode = (evt.which) ? evt.which : evt.keyCode;
        var number = el.value.split('.');
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            evt.preventDefault();
        }
        //just one dot
        if(number.length>1 && charCode == 46){
            evt.preventDefault();
        }
        //get the carat position
        var caratPos = this.getSelectionStart(el);
        var dotPos = el.value.indexOf(".");
        if( caratPos > dotPos && dotPos>-1 && (number[1].length > 5)){
            evt.preventDefault();
        }
    },
    getSelectionStart(o) {
        if (o.createTextRange) {
            var r = document.selection.createRange().duplicate()
            r.moveEnd('character', o.value.length)
            if (r.text == '') return o.value.length
            return o.value.lastIndexOf(r.text)
        } else return o.selectionStart
    },
  },
}
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>
