<template>
  <div>
    <div class="pa-4 pl-6  "> <span class="rounded amber lighten-2 pa-2 text-body-1 font-weight-medium">Editar Perfil</span> </div>
    <v-card class="ma-6 mt-1" max-width="800">
        <v-card-text class="pb-0">
            <div class="pa-2 body-1">Perfil</div>
            <v-form ref="formCliente" v-model="formulario.validar">
                <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-row>
                      <v-col
                    cols="12"
                    md="4"
                    >
                    <v-select
                        v-model="elem.tipodocumento"
                        :items="tipos"
                        label="Tipo de documento"
                        :rules="[v => !!v || 'Seleccione el tipo de documento']"
                    ></v-select>
                    </v-col>

                    <v-col
                    cols="12"
                    md="4"
                    >
                    <v-text-field
                        v-model="elem.nrodocumento"
                        label="Número de documento"
                        :rules="[v => !!v || 'El número es requerido']"
                        required
                    ></v-text-field>
                    </v-col>
                    </v-row>
                  </v-col>
                    <v-col
                    cols="12"
                    md="4"
                    class="pb-0"
                    >
                    <v-text-field
                        v-model="elem.nombres"
                        label="Nombres"
                        :rules="[v => !!v || 'El nombre es requerido']"
                        required
                    ></v-text-field>
                    </v-col>

                    <v-col
                    cols="12"
                    md="4"
                    class="pb-0"
                    >
                    <v-text-field
                        v-model="elem.apaterno"
                        label="Apellido Paterno"
                        :rules="[v => !!v || 'El apellidos parterno es requerido']"
                        required
                    ></v-text-field>
                    </v-col>

                    <v-col
                    cols="12"
                    md="4"
                    class="pb-0"
                    >
                    <v-text-field
                        v-model="elem.amaterno"
                        label="Apellido Materno"
                        :rules="[v => !!v || 'El apellido materno es requerido']"
                        required
                    ></v-text-field>
                    </v-col>

                    <v-col
                    cols="12"
                    md="6"
                    class="pb-0"
                    >
                    <v-text-field
                        v-model="elem.direccion"
                        label="Dirección"
                        required
                    ></v-text-field>
                    </v-col>

                    <v-col
                    cols="12"
                    md="6"
                    class="pb-0"
                    >
                    <v-text-field
                        v-model="elem.email"
                        :rules="emailReglas"
                        label="Email"
                        required
                    ></v-text-field>
                    </v-col>
                    
                    <v-col
                    cols="12"
                    md="3"
                    class="pb-0"
                    >
                    <v-text-field
                        v-model="elem.celular"
                        label="Celular"
                        required
                    ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    class="pb-0"
                    >
                    <v-text-field
                        v-model="elem.nacionalidad"
                        label="Nacionalidad"
                        :rules="[v => !!v || 'El pais de nacionalidad es requerido']"
                        required
                    ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    class="pb-0"
                    >
                    <v-select
                        v-model="elem.pep"
                        :items="peps"
                        label="PEP"
                        :rules="[v => !!v || 'Seleccione si es o no es PEP']"
                    >
                      <template v-slot:prepend>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              color="primary"
                              dark
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-help-circle-outline
                            </v-icon>
                          </template>
                          <div max-width="500">Personas Expuestas Políticamente son las personas naturales, <br>
                          nacionales o extranjeras, que cumplen o que en los últimos cinco (5) años han cumplido <br>
                          funciones públicas destacadas o funciones prominentes en una organización internacional.</div>
                          <span> </span>
                        </v-tooltip>
                      </template>
                    </v-select>
                    
                    </v-col>
                    <v-col
                    cols="12"
                    md="4"
                    class="pb-0"
                    >
                    <v-text-field
                        v-model="elem.ocupacion"
                        label="Ocupación"
                        :rules="[v => !!v || 'La ocupación es requerida']"
                        required
                    ></v-text-field>
                    </v-col>
                </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!formulario.validar" :loading="formulario.cargar" @click="modificar">Modificar</v-btn>
        </v-card-actions>
    </v-card>
    
    <v-row>
      <v-col cols="12" md="6" sm="12" lg="8" xl="8">
        <v-divider></v-divider>
        <div class="pa-4 pl-6  "> <span class="rounded amber lighten-2 pa-2 text-body-1 font-weight-medium">Administrar mas perfiles</span> </div>
        <v-row class="pa-6 pt-3">
          <v-col cols="12" md="12" sm="6" lg="4" xl="3" >
            <v-hover v-slot="{ hover }">
              <v-expand-transition>
              <v-card class="text-center text-truncate"  :color="hover ? 'orange lighten-5' : 'white'" @click="nuevaempresa" >
                    <v-card-actions>
                    </v-card-actions>
                    <v-icon
                      large
                      color="blue darken-2"
                    >
                      mdi-account-multiple-plus
                    </v-icon>
                    <v-card-title :class="diplayClass">Nuevo Perfil</v-card-title>
                    <v-card-text class="text--secondary ">Agregar perfil de empresa </v-card-text>
              </v-card>
              </v-expand-transition>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {mapState, mapActions} from 'vuex'

  export default {
    components: {
    },
    data: () => ({
      formulario : {
        validar: true,
        cargar: false
      },
      elem : {
        nombres: '',
        apellidos: '',
        cargo: '',
        correo: '',
        habilitado: ''
      },
      emailReglas: [
        v => !!v || 'Email es requerido',
        v => /.+@.+/.test(v) || 'Email no es valido',
      ],
      tipos: ['SIN DOCUMENTO', 'DNI', 'RUC', 'CARNET EXTRANJERIA', 'CÉDULA CIUDADANA' ,'PASAPORTE', 'CÉDULA DIPLOMATICA'],
      peps: ['SI', 'NO']
    }),
    async created() {
      await this.llenarUsuario()
      
    },
    computed: {
      ...mapState('usuario', ['usuarios', 'usuariosele']),
      diplayClass() {
        let classString = ''
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            classString = 'subtitle-2 text-no-wrap justify-center'
            break
          case 'sm':
            classString = 'subtitle-1 justify-center'
            break
          case 'md':
            classString = 'h5 justify-center'
            break
          case 'lg':
            classString = 'h6 justify-center'
            break
          case 'xl':
            classString = 'h6 justify-center'
            break
        }
        return classString
      }
    },
    methods: {
      ...mapActions('usuario', ['listarUsuarioPorId', 'editar']),
      async llenarUsuario(){
        await this.ListarUsuarioPorId(this.usuarios.id)
        //console.log(this.clientesele)
        this.elem.id = this.usuariossele.id
        this.elem.nombres = this.usuariossele.nombres
        this.elem.apellidos = this.usuariossele.apellidos
        this.elem.cargo = this.usuariossele.cargo
        this.elem.correo = this.usuariossele.correo
        this.elem.habilitado = this.clientesele.habilitado
      },
      async modificar() {
        this.formulario.cargar = true
        await this.editar(this.elem)
        await this.llenarUsuario()
        this.formulario.cargar = false
      }
    }
  }
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>